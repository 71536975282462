import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

function Contact() {
  const handleSubmit = (e) => {
    e.preventDefault();

    const name = e.target.from_name.value;
    const email = e.target.user_email.value;
    const phone = e.target.contact_number.value;
    const message = e.target.message.value;

    const whatsappMessage = `Hello! I'm ${name}.%0AMy email: ${email}%0APhone: ${phone}%0AMessage: ${message}`;
    const whatsappNumber = "919960991166";

    window.open(
      `https://wa.me/${whatsappNumber}?text=${whatsappMessage}`,
      "_blank",
      "noopener noreferrer"
    );
  };

  return (
    <>
      <Container fluid>
        <Row className="headingimg">
          <Col className="m-5">
            <h1 className="text-white m-4 mx-4 px-2 fw-bold text-center">
              CONTACT US
            </h1>
            <div className="text-white m-4 fw-bold text-center">
              <a href="/" className=" text-white m-3 text-decoration-none">
                Home
              </a>
              <i className="fa-solid fa-circle-arrow-right fa-beat"></i>
              <a className="text-white text-decoration-none m-3">CONTACT US</a>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <h6 className="text-muted m-3">We Are Available</h6>
            <h4 className="m-3">GET IN TOUCH</h4>
            <p className="m-3 text-muted">
              You can talk to our online representative at any time. Please use
              our Live Chat System on our website or Fill up below instant
              messaging programs. Please be patient, <br /> We will get back to
              you. Our 24/7 Support, General Inquireies Phone: +91-9960-991-166
            </p>
          </Col>
        </Row>

        <Row className="m-3 p-3">
          <Col lg={3}>
            <Row className=" m-1 border p-2 shadow">
              <Col sm={2} className="p-2 text-sm-end text-center">
                <i className="fa-solid fa-envelope fs-1 text-green"></i>
              </Col>
              <Col>
                <div className="text-sm-start text-center text-muted">
                  <a
                    href="mailto:info@royalgreenhouse.co.in"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@royalgreenhouse.co.in
                  </a>
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg={3}>
            <Row className="m-1 border p-2 shadow">
              <Col sm={2} className="p-2 text-sm-end text-center">
                <i className="fa-solid fa-location-dot fs-1 text-green"></i>
              </Col>
              <Col>
                <div className="text-sm-start text-center text-muted">
                  <a
                    href="https://maps.app.goo.gl/zaBudTaYEaCEqjAJ8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    #Office Address
                  </a>
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg={3}>
            <Row className="m-1 border p-2 shadow">
              <Col sm={2} className="p-2 text-sm-end text-center">
                <i className="fa-solid fa-location-dot fs-1 text-green"></i>
              </Col>
              <Col>
                <div className="text-sm-start text-center text-muted">
                  <a
                    href="https://maps.app.goo.gl/2fxpGTLAMZhr82Vz7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    #Factory Address
                  </a>
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg={3}>
            <Row className="m-1 border p-2 shadow">
              <Col sm={2} className="p-2 text-sm-end text-center">
                <i className="fa-solid fa-phone fs-1 text-green"></i>
              </Col>
              <Col>
                <div className="text-sm-start text-center text-muted">
                  <a href="tel:+919960991166"> +91-9960-991-166</a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col lg={8}>
            <Form className="m-3 bg-white rounded-4" onSubmit={handleSubmit}>
              <Row className="m-3 p-3">
                <Col lg={12}>
                  <Form.Control
                    type="text"
                    placeholder="Enter Name"
                    className="p-2 m-2"
                    name="from_name"
                    required
                  />
                </Col>
                <Col lg={12}>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    className="p-2 m-2"
                    name="user_email"
                    required
                  />
                </Col>
                <Col lg={12}>
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    className="p-2 m-2"
                    name="contact_number"
                    required
                  />
                </Col>
                <Col lg={12}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    className="p-2 m-2"
                    name="message"
                    required
                  />
                </Col>
                <Col lg={12}>
                  <Button
                    variant="outline-success"
                    type="submit"
                    className="p-2 m-2 mb-4 fw-bold"
                  >
                    Send
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col lg={4} className="p-3">
            <div className="m-3">
              <a
                href="https://www.youtube.com/channel/UCHkBnZS-kJXRYSUIUF2u2pA"
                className="text-decoration-none"
              >
                <i className="fa-brands fa-youtube fs-1 mx-2 text-danger rotate"></i>
                Follow on Youtube
              </a>
            </div>

            <div className="m-3">
              <a
                href="https://www.instagram.com/royal_green_house_/"
                className="text-decoration-none"
              >
                <i className="fa-brands fa-instagram fs-1 mx-2 text-pink rotate"></i>
                Follow on Instagram
              </a>
            </div>
            <div className="m-3">
              <a
                href="https://www.facebook.com/people/Royalgreen-House/pfbid06Ein7Yjsr32vBRywLq5RBQS3jcaVRxY1U81zwac1Ybf9MkRLBHw9SMojgPt4cDSCl/"
                target="_blank"
                className="text-decoration-none"
              >
                <i className="fa-brands fa-facebook fs-1 mx-2 rotate"></i>
                Follow on Facebook
              </a>
            </div>
            <div className="m-3">
              <a
                href="https://www.linkedin.com/in/royal-green-house-construction-i-pvt-ltd-692664268?original_referer="
                className="text-decoration-none"
              >
                <i className="fa-brands fa-linkedin fs-1 mx-2 text-info rotate"></i>
                Follow on Linkedin
              </a>
            </div>
            <div className="m-3">
              <a
                href="https://wa.me/+919960991166"
                target="_blank"
                className="text-decoration-none"
              >
                <i className="fa-brands fa-whatsapp fs-1 mx-2 text-green rotate"></i>
                Follow on Whatsapp
              </a>
            </div>

            <div className="m-3">
              <a
                href="https://twitter.com/GreenhouseRoya1"
                target="_blank"
                className="text-decoration-none"
              >
                <i className="fa-brands fa-twitter fs-1 mx-2 text-info rotate"></i>
                Follow on Twitter
              </a>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <span className="fw-bold text-center">
            Explore our successful hydroponic projects.
          </span>
          <a href="/gallery" className="text-center">
            Explore Our Projects
          </a>
        </Row>

        <Row>
          <Col>
            <div className="m-3">
              <iframe
                width="100%"
                height="400"
                src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=Royal%20Green%20House%2C%20Satara+(Title)&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight="0"
                marginWidth="0"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Contact;
